@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light|Signika:300,600');


$primary: #ab1e32; /* MAIN COLOR */
$secondary: $primary; /* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Signika', sans-serif;
  color: $gray;
}
.navbar {
  margin-bottom: 0;
  z-index: 1030;
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  height: 100px;
  position: relative;

  .navbar-nav>li>a {
    line-height:68px;
    background: $wht;
    @media (max-width:767px){
      line-height: 20px;
    }
  }
  .navbar-nav>li {
    background: $wht;
  }
  .nav .nav-divider {
    margin: 0px ;
    background: #ccc;
  }
  @media (max-width: 767px){
    height: 80px;
    .logo {
      max-height: 80px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.modal-dialog{
  width: 250px;
  text-align: center;
  margin:7em auto;
    input[type=submit]{
     padding: 15px 25px;
     width: 100%;
     color:$blk !important;
   }
 }
 .modal-header, .modal-footer{
  background: #333;
  color: #fff;
 }
 input#username{
  margin-bottom: 20px;
 }
 .modal-title {
   color:#fff
 }
 .modal-dialog .btn-default {
	 width:100%;
	 background: #333;
	 border: none;
	 color:#fff}

.navbarFixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  // background-color: $secondary;
}
.cta {
	background: $wht;
	padding: 15px 30px;
	margin: 5px;
	color: $primary;
	&:hover{
		background: $primary;
		color: $wht;
		border: 1px solid $wht;
		transition: all 0.7s ease 0s;

	}

}
.text-white {color: $wht;}
.hero-info{
	background: $primary;
  color: $wht;
  padding: 50px 0px;
  p {
    font-size: 1.2em;
    color: $wht;
    padding: 0 20px;
  }
	h2 {   font-family: 'Shadows Into Light', cursive;}
}
.hero-quote {
  padding: 100px 0;
  background: darken($primary, 4%);

    @media(max-width: 767px){
      padding:40px 0;
    }
    p {
      font-size: 1.5em;
      color: $wht;
      padding: 0 20px;
      @media (max-width: 767px){
        font-size: 1.2em;
      }
    }
  h3 {
    font-size: 2em;
    font-family: 'Shadows Into Light', cursive;
    line-height:1.6em;
    font-weight: lighter;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.6em;
    }
  }
}
.hero-process {
  padding: 250px 0;
  background: url('../img/img-full-1.jpg') top center/cover;
  background-color: #e2e2e2;
  @media(max-width: 767px){
    padding: 20px 0;
  }
  h2 {
    font-family: 'Shadows Into Light', cursive;
    font-size: 3em;
    line-height: 2em;
    @media (max-width: 767px){
      font-size: 1.8em;
      line-height: auto;
    }
  }
  p {
    font-size: 1.6em;
    margin: 100px 0;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }

  }
}
.form {
  background: url('../img/bg1.jpg') no-repeat center/cover;
  background-color: #edeff1;
  padding: 0;
  .col-md-5{
    background-color: $blk;
    padding: 100px 50px;
    color: $wht;
    @media(max-width: 767px){
      padding:40px 20px;
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 36px;
    }
    a {
      text-decoration: none;
      color: #ccc;
      font-weight: 100;
    }
  }
  .col-md-7 {
    padding: 100px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
    }

    p {
      font-size:1.2em;
      line-height:1.5em;
      color:#999;
    }
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background: $primary;
  color: #ccc;
  a {
    text-decoration: none;
    color: $blk;
  }
}
